html,
body {
  width: 100%;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body.progress {
  cursor: progress; }

#app {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  #app.no-scroll {
    overflow: hidden;
    -webkit-overflow-scrolling: auto; }

.pages {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.page {
  z-index: 1;
  position: absolute;
  width: 100%;
  min-height: 100%; }

.mobile .scrollbar-track,
.tablet .scrollbar-track {
  display: none !important; }
